import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useRef,
  useState,
} from 'react'
import { CAI_CHAT_FLOWS } from 'src/constants/cai-chat.constant'

type Type = {
  show: boolean
  flow: string
  tourAudio: HTMLAudioElement | null

  setShow: Dispatch<SetStateAction<boolean>>
  setFlow: Dispatch<SetStateAction<string>>
  setTourAudio: Dispatch<SetStateAction<HTMLAudioElement | null>>

  notificationRef: RefObject<HTMLDivElement> | null
  sizeBtnRef: RefObject<HTMLDivElement> | null
  draggingBtnRef: RefObject<HTMLDivElement> | null
  librarianRef: RefObject<HTMLButtonElement> | null
  feedFwdRef: RefObject<HTMLButtonElement> | null
  agentChatRef: RefObject<HTMLDivElement> | null
  newChatRef: RefObject<HTMLButtonElement> | null
  languageSelectionRef: RefObject<HTMLDivElement> | null
  profileSelectionRef: RefObject<HTMLDivElement> | null
  aiChatIconRef: RefObject<HTMLButtonElement> | null
  hubMenuItem: RefObject<HTMLDivElement> | null
  tourOpen: boolean
  setTourOpen: Dispatch<SetStateAction<boolean>>
}

export const CaiPopupContext = createContext<Type>({
  show: false,
  flow: CAI_CHAT_FLOWS.DEFAULT,
  tourAudio: null,
  tourOpen: false,
  setTourOpen: () => {},
  setShow: () => {},
  setFlow: () => {},
  setTourAudio: () => {},

  notificationRef: null,
  sizeBtnRef: null,
  draggingBtnRef: null,
  librarianRef: null,
  feedFwdRef: null,
  agentChatRef: null,
  newChatRef: null,
  languageSelectionRef: null,
  profileSelectionRef: null,
  aiChatIconRef: null,
  hubMenuItem: null,
})

export default function CaiPopupProvider({ children }: PropsWithChildren) {
  const [show, setShow] = useState(false)
  const [flow, setFlow] = useState(CAI_CHAT_FLOWS.DEFAULT)
  const [tourAudio, setTourAudio] = useState<HTMLAudioElement | null>(null)
  const [tourOpen, setTourOpen] = useState(false)

  const notificationRef = useRef<HTMLDivElement | null>(null)
  const librarianRef = useRef<HTMLButtonElement | null>(null)
  const feedFwdRef = useRef<HTMLButtonElement | null>(null)
  const agentChatRef = useRef<HTMLDivElement | null>(null)
  const sizeBtnRef = useRef<HTMLDivElement | null>(null)
  const draggingBtnRef = useRef<HTMLDivElement | null>(null)
  const newChatRef = useRef<HTMLButtonElement | null>(null)
  const languageSelectionRef = useRef<HTMLDivElement | null>(null)
  const profileSelectionRef = useRef<HTMLDivElement | null>(null)
  const aiChatIconRef = useRef<HTMLButtonElement | null>(null)
  const hubMenuItem = useRef<HTMLDivElement | null>(null)

  return (
    <CaiPopupContext.Provider
      value={{
        show,
        setShow,
        tourOpen,
        setTourOpen,
        flow,
        setFlow,
        tourAudio,
        setTourAudio,
        sizeBtnRef,
        draggingBtnRef,
        librarianRef,
        feedFwdRef,
        notificationRef,
        agentChatRef,
        newChatRef,
        languageSelectionRef,
        profileSelectionRef,
        aiChatIconRef,
        hubMenuItem,
      }}
    >
      {children}
    </CaiPopupContext.Provider>
  )
}

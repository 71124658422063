import React, {
  Dispatch,
  MutableRefObject,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useRef,
  useState,
} from 'react'

type Type = {
  selectedReceiver: string
  setSelectedReceiver: Dispatch<SetStateAction<string>>
  feedbackModal: boolean
  canvasRef: MutableRefObject<HTMLDivElement | null>
  wankRef: MutableRefObject<HTMLDivElement | null>
  sendRef: MutableRefObject<HTMLButtonElement | null>
  reviewRef: MutableRefObject<HTMLDivElement | null>
  interactiveRef: MutableRefObject<HTMLDivElement | null>
  actionRef: MutableRefObject<HTMLDivElement | null>
  tourAudio: HTMLAudioElement | null

  setFeedbackModal: Dispatch<SetStateAction<boolean>>
  setTourAudio: Dispatch<SetStateAction<HTMLAudioElement | null>>
}

export const CommunicationContext = createContext<Type>({
  feedbackModal: false,
  canvasRef: { current: null },
  wankRef: { current: null },
  sendRef: { current: null },
  reviewRef: { current: null },
  interactiveRef: { current: null },
  actionRef: { current: null },
  selectedReceiver: '',
  setSelectedReceiver: () => {},

  tourAudio: null,
  setFeedbackModal: () => {},
  setTourAudio: () => {},
})

export default function CommunicationProvider({ children }: PropsWithChildren) {
  const [feedbackModal, setFeedbackModal] = useState(false)
  const [tourAudio, setTourAudio] = useState<HTMLAudioElement | null>(null)
  const canvasRef = useRef<HTMLDivElement | null>(null)
  const wankRef = useRef<HTMLDivElement | null>(null)
  const sendRef = useRef<HTMLButtonElement | null>(null)
  const reviewRef = useRef<HTMLDivElement | null>(null)
  const interactiveRef = useRef<HTMLDivElement | null>(null)
  const actionRef = useRef<HTMLDivElement | null>(null)
  const [selectedReceiver, setSelectedReceiver] = useState('')

  return (
    <CommunicationContext.Provider
      value={{
        feedbackModal,
        setFeedbackModal,
        canvasRef,
        wankRef,
        sendRef,
        reviewRef,
        interactiveRef,
        tourAudio,
        setTourAudio,
        selectedReceiver,
        setSelectedReceiver,
        actionRef,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  )
}

import { Icon } from '@iconify/react'
import { Space } from 'antd'
import get from 'lodash/get'
import React, { ReactElement, forwardRef, useContext } from 'react'
import {
  ChangeEvent,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { useSpeechRecognition } from 'react-speech-recognition'
import STTButton from 'src/components/common/STTButton'
import { TextInput } from 'src/components/inputs'
import { CommunicationContext } from 'src/context/CommunicationContext'
import styled from 'styled-components'

const StyledDiv = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
`

interface ChatInputProps {
  txtMessage: string
  setTxtMessage: Dispatch<SetStateAction<string>>
  onMessage: (msg: string, hidden: boolean) => void
  awaitingResponse: boolean
  onChangeMessageText: (str: string) => void
  newChat: (selectedFlow?: string) => Promise<void>
  retainInput?: boolean
  choicesElement?: ReactNode
  prefix?: ReactNode
  maxHeight?: string
}

export const ChatInput: FC<ChatInputProps> = ({
  txtMessage,
  setTxtMessage,
  onMessage,
  awaitingResponse = false,
  onChangeMessageText,
  retainInput = false,
  prefix,
  choicesElement,
  maxHeight = '90%',
}) => {
  const { t } = useTranslation()
  const speechApi = useSpeechRecognition()
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)
  const isSmallScreen = useMediaQuery({
    query: '(max-width: 1350px)',
  })
  const { actionRef } = useContext(CommunicationContext)

  const isValidJSX = choicesElement && isValidElement(choicesElement)
  const jsxChoicesLength = get(choicesElement, 'props.choices', []).length || 0

  const handleSendMessage = () => {
    onMessage(txtMessage, false)
    if (!retainInput) setTxtMessage('')
    speechApi.resetTranscript()
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSendMessage()
      speechApi.resetTranscript()
    }
  }

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight
    }
  }, [txtMessage])

  return (
    <div
      className="d-flex flex-column justify-content-center my-2"
      style={{
        width: '100%',
        flexShrink: 0,
      }}
    >
      <StyledDiv>
        <Space.Compact
          block
          style={{
            display: 'flex',
            alignItems: 'center',
            width: isSmallScreen ? '100%' : '90%',
          }}
        >
          <TextInput
            ref={textAreaRef}
            disabled={awaitingResponse}
            propLabel={false}
            value={txtMessage}
            placeholder={t(
              'feedbacks.activeChatMsg',
              'Write your message to Cai here',
            )}
            prefix={
              isValidElement(prefix)
                ? cloneElement(prefix as ReactElement, {
                    ref: actionRef,
                  })
                : null
            }
            suffix={
              <div className="d-flex flex-row align-items-center">
                <STTButton
                  setText={setTxtMessage}
                  {...speechApi}
                  awaitingResponse={awaitingResponse}
                  height="24"
                  width="24"
                />
                <button
                  className="d-flex justify-content-center align-items-center btn btn-primary align-items-center"
                  disabled={awaitingResponse}
                  style={{
                    marginLeft: 5,
                    borderRadius: '50%',
                    padding: '4px',
                    width: '30px',
                    height: '30px',
                    paddingLeft: '6px',
                  }}
                  onClick={handleSendMessage}
                >
                  <Icon height="24" icon="fluent:send-48-regular" width="24" />
                </button>
              </div>
            }
            sx={{
              borderColor: 'transparent',
              background: 'white',
              alignItems: 'center',
              padding: '10px',
              borderTopLeftRadius: '10px',
              borderTopRightRadius: '10px',
              borderBottomLeftRadius:
                isValidJSX && jsxChoicesLength ? '0' : '10px',
              borderBottomRightRadius:
                isValidJSX && jsxChoicesLength ? '0' : '10px',
            }}
            onKeyDown={handleKeyDown}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              onChangeMessageText(e.target.value)
            }
          />
        </Space.Compact>
      </StyledDiv>
      {choicesElement && isValidElement(choicesElement) && jsxChoicesLength ? (
        <StyledDiv
          style={{
            paddingBottom: '20px',
            opacity: awaitingResponse ? 0.5 : 1,
          }}
        >
          <Space
            style={{
              height: 'auto',
              background: '#F9F8FF',
              borderBottomLeftRadius: '10px',
              borderBottomRightRadius: '10px',
              padding: '20px',
              cursor: awaitingResponse ? 'not-allowed' : 'pointer',
            }}
          >
            {cloneElement(choicesElement)}
          </Space>
        </StyledDiv>
      ) : null}
    </div>
  )
}

import { Icon } from '@iconify/react'
import { Dropdown, Form, Space, Tooltip } from 'antd'
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { AuthContext } from 'src/components/Auth/AuthProvider'
import { CopyButton } from 'src/components/buttons'
import { TextInput } from 'src/components/inputs/'
import { getSummaryCanvas } from 'src/utils/platform'
import FeedFwdCanvasHeader from './FeedFwdCanvasHeader'
import FeedFwdModal from './FeedFwdModal'
import STTButton from 'src/components/common/STTButton'
import { useSpeechRecognition } from 'react-speech-recognition'
import { CommunicationContext } from 'src/context/CommunicationContext'

type FormatType = 'direct' | 'structured'

interface FeedFwdCanvasProps {
  handleMouseDownFirst: (e: React.MouseEvent<HTMLDivElement>) => void
  awaitingResponse: boolean
  initialFeedback?: string
  setInitialFeedback: Dispatch<SetStateAction<string>>
  flow?: string
  currentFeedback: string
  setFlow: Dispatch<SetStateAction<string>>
  setCurrentFeedback: Dispatch<SetStateAction<string>>
}

const FeedFwdCanvas: FC<FeedFwdCanvasProps> = (props) => {
  const {
    awaitingResponse,
    initialFeedback = '',
    setInitialFeedback,
    flow,
    currentFeedback,
    setCurrentFeedback,
    setFlow,
  } = props
  const { t } = useTranslation()
  const { auth } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [form] = Form.useForm()
  const { canvasRef, wankRef, sendRef, setFeedbackModal } =
    useContext(CommunicationContext)

  const targetIdVal = Form.useWatch('targetId', form)

  const handleSummaryCanvas = async (formatType: FormatType) => {
    setLoading(true)
    const newText = await getSummaryCanvas(
      auth?.token,
      initialFeedback,
      formatType,
    )
    setInitialFeedback(newText)
    setLoading(false)
  }

  const items = [
    {
      key: '1',
      label: (
        <div
          className="d-flex  align-items-center"
          onClick={() => handleSummaryCanvas('structured')}
        >
          <Icon
            height="20"
            icon="material-symbols-light:document-scanner"
            width="20"
          />
          <div className="ms-1">{t('feedbacks.items.formatStructure')}</div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div
          className="d-flex  align-items-center"
          onClick={() => handleSummaryCanvas('direct')}
        >
          <Icon height="20" icon="material-symbols-light:pending" width="20" />
          <div className="ms-1">{t('feedbacks.items.rewrite')}</div>
        </div>
      ),
    },
  ]

  const { listening, ...speechApi } = useSpeechRecognition()

  return (
    <div
      ref={canvasRef}
      className="d-flex w-100 h-100 flex-column position-relative p-1"
    >
      <FeedFwdCanvasHeader
        currentFeedback={currentFeedback}
        flow={flow}
        form={form}
      />
      <div
        style={{
          width: '100%',
          height: '100%',
          background: 'white',
          marginBottom: '5px',
          position: 'relative',
          paddingBottom: '40px',
          pointerEvents: loading ? 'none' : undefined,

          color: awaitingResponse || loading ? 'rgba(0,0,0,0.25)' : 'black',
        }}
      >
        <TextInput
          autoSize={false}
          disabled={loading || awaitingResponse}
          value={currentFeedback}
          placeholder={t(
            'feedbacks.actionChatMsg',
            'You can type directly here...',
          )}
          style={{
            height: '100%',
            resize: 'none',
            background:
              awaitingResponse || loading ? 'var(--border-color)' : 'white',
          }}
          sx={{
            padding: 0,
            width: '100%',
            height: '100%',
            borderRadius: 0,
            border: 0,
            background: 'white',
          }}
          sxBox={{
            height: '100%',
            display: 'block',
            maxHeight: '100%',
          }}
          onChange={(e) => setInitialFeedback(e.target.value)}
        />

        <div
          className="d-flex justify-content-start align-items-center w-100"
          style={{
            background:
              awaitingResponse || loading ? 'var(--border-color)' : 'white',
            position: 'absolute',
            bottom: 0,
            left: 0,
            padding: '5px',
          }}
        >
          <Space>
            <STTButton
              setText={setInitialFeedback}
              {...speechApi}
              awaitingResponse={awaitingResponse}
              height="24"
              width="24"
            />
            <Dropdown
              disabled={awaitingResponse}
              menu={{ items }}
              placement="topLeft"
            >
              <Icon
                ref={wankRef as any}
                color="var(--color-primary)"
                height="24"
                icon="ph:magic-wand-fill"
                type="button"
                width="24"
              />
            </Dropdown>
            <CopyButton
              text={initialFeedback}
              style={{
                background: 'var(--color-primary)',
                color: 'white',
                borderRadius: '50%',
                padding: '8px',
              }}
            />
          </Space>

          <FeedFwdModal
            currentFeedback={currentFeedback}
            flow={flow}
            form={form}
            setCurrentFeedback={setCurrentFeedback}
            setFlow={setFlow}
          />
        </div>
      </div>
    </div>
  )
}

export default FeedFwdCanvas
